import { css } from "@stitches/react";

export const vGutter = "1.5rem";
export const hGutter = "1.5rem";

export const colors = {
  black: {
    text: "#010102",
    dark: "#020204",
  },
  gray: {
    light: "#F5F5F5",
    base: "#D6D6D6",
    dark: "#2D2D2D",
  },
  blue: {
    light: "#004E98",
    base: "#19294D",
    dark: "#14203E",
  },
  yellow: {
    base: "#E0BB00",
  },
  green: {
    base: "#38b000",
    light: "#9ef01a",
    dark: "#006400",
  },
  red: {
    base: "#b21e35",
    light: "#da1e37",
    dark: "#85182a",
  },
};

export const defaultStyles = css({
  padding: `${vGutter} ${hGutter}`,
  boxShadow: `rgba(0, 0, 0, 0.5) 0px 2px 15px`,
  backgroundColor: colors.blue.base,
  gridColumn: "1 / 13",
  borderRadius: `calc(${vGutter}/2)`,
  variants: {
    device: {
      large: {
        height: "fit-content",
        alignSelf: "center",
        gridColumn: "3/11",
        margin: `calc(${vGutter} * 2) 0`,
        padding: `${vGutter} calc(${vGutter} * 2)`,
      },
    },
  },
});
