import { styled } from "@stitches/react";
import { motion } from "framer-motion";
import { useMemo, VFC } from "react";
import { colors, vGutter } from "../styles/params";

export const Memoji: VFC<{ img: string; emojiSize?: number }> = ({
  img,
  emojiSize = 6,
}) => {
  const MemojiContainer = useMemo(
    () =>
      styled(motion.div, {
        width: `calc(${vGutter} * ${emojiSize})`,
        height: `calc(${vGutter} * ${emojiSize})`,
        boxShadow: `rgba(0, 0, 0, 0.5) 0px 2px 15px`,
        backgroundColor: colors.yellow.base,
        marginBottom: vGutter,
        alignSelf: "center",
        borderRadius: "50%",
        display: "flex",
        img: {
          clipPath: "circle(48% at 50% 50%)",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          objectPosition: "right",
        },
        variants: {
          media: {
            large: {
              right: `-${vGutter}`,
              top: `-${vGutter}`,
              position: "absolute",
            },
          },
        },
      }),
    [emojiSize]
  );

  return (
    <MemojiContainer
      initial={{ rotate: "0deg" }}
      animate={{
        rotate: ["0deg", "-10deg", "10deg", "0deg"],
        transitionEnd: {
          rotate: "0deg",
        },
      }}
    >
      <img src={img} alt="memoji" />
    </MemojiContainer>
  );
};
