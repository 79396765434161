import { styled } from "../styles/stitches.config";
import { VFC } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

import CallMeMemoji from "../assets/chiamami-memoji.webp";
import { buttonVariants, transitionIn } from "../styles/animations";
import { colors, defaultStyles, vGutter } from "../styles/params";
import { motion } from "framer-motion";
import { Memoji } from "../components/Memoji";
import { mail, phoneNumber } from "../data/defaults";
import { StyledButton } from "../styles/styledComponents";

const Container = styled(motion.div, defaultStyles, {
  display: "flex",
  flexDirection: "column",
  paddingTop: `calc(${vGutter} * 2)`,
  backgroundColor: colors.blue.base,
  justifyContent: "center",
  h1: {
    marginTop: vGutter,
  },
  variants: {
    device: {
      large: {
        gridColumn: "3/11",
        margin: `calc(${vGutter} * 2) 0`,
        padding: `${vGutter} calc(${vGutter} * 2)`,
      },
    },
  },
});

const PhoneContainer = styled("div", {
  marginTop: `calc(${vGutter} * 2)`,
  display: "grid",
  gridTemplateColumns: "0.2fr 1fr",
  columnGap: vGutter,
  marginBottom: `calc(${vGutter} * 2)`,
  width: "100%",
  justifyItems: "start",
  alignItems: "center",
  fontSize: "1.2rem",
  fontWeight: "500",
  button: {
    fontSize: "1.5rem",
    width: "100%",
  },
});

const MailContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "0.2fr 1fr",
  columnGap: vGutter,
  rowGap: `calc(${vGutter} * 2)`,
  alignItems: "center",
  marginBottom: vGutter,
});

export const Contacts: VFC = () => {
  return (
    <Container device={{ "@bp3": "large" }} {...transitionIn}>
      <Memoji img={CallMeMemoji} />
      <h1>Contattami</h1>
      <p>Chiama col tasto qui in basso o scrivimi una mail</p>
      <PhoneContainer>
        <FaPhoneAlt color={colors.gray.base} size={"2rem"} />
        <StyledButton
          onClick={() => window.open(`tel:${phoneNumber}`)}
          css={{ borderRadius: `calc(${vGutter} / 2)`, fontWeight: 600 }}
          {...buttonVariants}
        >
          <p>{phoneNumber}</p>
        </StyledButton>
      </PhoneContainer>
      <MailContainer>
        <IoMdMail color={colors.gray.base} size={"2rem"} />
        <StyledButton
          onClick={() => window.open(`mailto:${mail}`)}
          css={{ borderRadius: `calc(${vGutter} / 2)` }}
          {...buttonVariants}
        >
          Invia mail
        </StyledButton>
      </MailContainer>
    </Container>
  );
};
