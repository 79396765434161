import { VFC } from "react";
import { styled } from "@stitches/react";
import { colors, hGutter, vGutter } from "../styles/params";
import { useNavigate } from "react-router-dom";

const StyledHeader = styled("nav", {
  backgroundColor: colors.blue.base,
  boxShadow: `rgba(0, 0, 0, 0.2) 0px 2px 15px`,
  padding: `${vGutter} ${hGutter}`,
  display: "flex",
  height: "fit-content",
  justifyContent: "center",
  alignItems: "flex-end",
  color: colors.gray.light,
  gridColumn: "1/13",
  h1: {
    cursor: "pointer",
  },
});

export const Header: VFC = () => {
  const navigate = useNavigate();

  return (
    <StyledHeader>
      <h1 onClick={() => navigate("/")}>Edil Decorgesso</h1>
    </StyledHeader>
  );
};
