import { VFC } from "react";
import { Header } from "./components/Header";
import { AboutUs } from "./sections/AboutUs";
import { Contacts } from "./sections/Contacts";
import { Layout } from "./components/Layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "react-multi-carousel/lib/styles.css";
import { Navbar } from "./components/Navbar";
import { MenuToggler } from "./components/MenuToggler";
import { ImageContainer } from "./components/ImageContainer";
import { MyWorks } from "./sections/MyWorks";
import { soffittiArray, tinteArray, cartongessoArray } from "./data/defaults";

export const App: VFC = () => {
  return (
    <Router>
      <Navbar />
      <Layout>
        <Header />
        <Routes>
          <Route path="/" element={<AboutUs />} />
          <Route path="/contatti" element={<Contacts />} />
          <Route path="/lavori" element={<MyWorks />} />
          <Route
            path="/lavori/soffitti"
            element={<ImageContainer images={soffittiArray} title="Soffitti" />}
          />
          <Route
            path="/lavori/tinte"
            element={<ImageContainer images={tinteArray} title="Tinte" />}
          />
          <Route
            path="/lavori/cartongesso"
            element={
              <ImageContainer images={cartongessoArray} title="Cartongesso" />
            }
          />
        </Routes>
      </Layout>
      <MenuToggler />
    </Router>
  );
};
