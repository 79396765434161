import { styled } from "../styles/stitches.config";
import { motion } from "framer-motion";
import { VFC } from "react";
import { buttonVariants, transitionIn } from "../styles/animations";
import { StyledButton } from "../styles/styledComponents";
import { Memoji } from "../components/Memoji";
import Idea from "../assets/idea-memoji.webp";
import { colors, defaultStyles, vGutter } from "../styles/params";
import { useNavigate } from "react-router-dom";

const Container = styled(motion.section, defaultStyles, {
  backgroundColor: colors.blue.base,
  paddingTop: `calc(${vGutter} * 2)`,
});

const InnerContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  h1: {
    fontSize: "2rem",
    marginTop: vGutter,
  },
});

const StyledButtonContainer = styled(motion.div, {
  display: "grid",
  width: "100%",
  gridTemplateColumns: "repeat(2, 1fr)",
  "& button:nth-of-type(3)": {
    gridColumn: "1/3",
  },
  gap: vGutter,
  marginTop: `calc(${vGutter} * 2)`,
  marginBottom: vGutter,
  button: {
    borderRadius: `calc(${vGutter} / 2)`,
  },
});

export const MyWorks: VFC = () => {
  const navigate = useNavigate();

  return (
    <Container device={{ "@bp3": "large" }} {...transitionIn}>
      <InnerContainer>
        <Memoji img={Idea} />
        <h1>Cosa so fare?</h1>
        <p>Ecco alcuni dei miei lavori, ma so fare molto altro!</p>
        <StyledButtonContainer
          animate={{ scale: 1, transition: { duration: 0.6 } }}
          initial={{ scale: 1 }}
        >
          <StyledButton
            {...buttonVariants}
            layout
            onClick={() => {
              navigate("/lavori/soffitti");
            }}
          >
            Soffitti
          </StyledButton>
          <StyledButton
            {...buttonVariants}
            onClick={() => {
              navigate("/lavori/tinte");
            }}
          >
            Tinte
          </StyledButton>
          <StyledButton
            {...buttonVariants}
            onClick={() => navigate("/lavori/cartongesso")}
          >
            Cartongesso
          </StyledButton>
        </StyledButtonContainer>
      </InnerContainer>
    </Container>
  );
};
