import { styled } from "../styles/stitches.config";
import { useContext, VFC } from "react";
import { ButtonContext } from "../context/ButtonContext";
import { FiMenu } from "react-icons/fi";
import { colors, vGutter } from "../styles/params";

const MenuButton = styled("button", {
  boxShadow: `rgba(0, 0, 0, 0.5) 0px 2px 15px`,
  outline: "none",
  border: `1px solid transparent`,
  borderRadius: "50%",
  fontSize: "1.4rem",
  color: colors.blue.base,
  backgroundColor: colors.yellow.base,
  width: `calc(${vGutter} * 2 )`,
  height: `calc(${vGutter} * 2 )`,
  position: "fixed",
  bottom: vGutter,
  right: vGutter,
  cursor: "pointer",
  span: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  variants: {
    manageButton: {
      hide: {
        display: "none",
      },
    },
  },
});

export const MenuToggler: VFC = () => {
  const { buttons, handleOnClick } = useContext(ButtonContext);
  return (
    <MenuButton
      manageButton={buttons.toggleMenu ? "hide" : undefined}
      onClick={() => {
        handleOnClick("toggleMenu");
      }}
    >
      <span>
        <FiMenu />
      </span>
    </MenuButton>
  );
};
