import { styled } from "../styles/stitches.config";
import { motion } from "framer-motion";
import { VFC } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { transitionIn } from "../styles/animations";
import { vGutter, defaultStyles } from "../styles/params";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "../styles/styledComponents";

const StyledImageContainer = styled(motion.div, defaultStyles, {
  display: "grid",
  gridTemplateColumns: `1fr`,
  rowGap: vGutter,
  img: {
    width: "100%",
    height: "40vh",
    objectFit: "cover",
    objectPosition: "center",
  },
});

const StyledImageInnerContainer = styled("div", {
  img: {
    width: "100%",
    height: "40vh",
    objectFit: "cover",
    objectPosition: "center",
  },
  variants: {
    device: {
      large: {
        img: {
          width: "100%",
          height: "70vh",
          objectFit: "cover",
          objectPosition: "center",
        },
      },
    },
  },
});

const InnerButton = styled("span", {
  display: "flex",
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
});

export const ImageContainer: VFC<{ images: string[]; title: string }> = ({
  images,
  title,
}) => {
  const navigate = useNavigate();
  return (
    <StyledImageContainer device={{ "@bp3": "large" }} {...transitionIn}>
      <h1>{title}</h1>
      {images.map((img, index) => (
        <StyledImageInnerContainer device={{ "@bp3": "large" }}>
          <img src={img} alt={`Soffitto numero ${index}`} />
        </StyledImageInnerContainer>
      ))}

      <StyledButton
        onClick={() => navigate("/lavori")}
        css={{ borderRadius: `calc(${vGutter} / 2)` }}
      >
        <InnerButton>
          <MdOutlineArrowBackIos />
          <p>Indietro</p>
        </InnerButton>
      </StyledButton>
    </StyledImageContainer>
  );
};
