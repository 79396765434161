import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { ContextProvider } from "./context/ButtonContext";
import reportWebVitals from "./reportWebVitals";
import { globalCss } from "./styles/stitches.config";

const globalStyles = globalCss({
  "*": {
    margin: 0,
    padding: 0,
    fontFamily: `Roboto, sans-serif`,
  },
  h1: {
    fontSize: "1.8rem",
    lineHeight: "3rem",
  },
  h2: {
    fontSize: "1.5rem",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      {globalStyles()}
      <App />
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
