import soffitti1 from "../assets/lavori/soffitto1.jpeg";
import soffitti2 from "../assets/lavori/soffitto2.jpg";
import soffitti3 from "../assets/lavori/soffitto3.jpg";
import cartongesso1 from "../assets/lavori/cartongesso1.jpg";
import cartongesso2 from "../assets/lavori/cartongesso2.jpg";
import cartongesso3 from "../assets/lavori/cartongesso3.jpeg";
import tinte1 from "../assets/lavori/tinte1.jpg";
import tinte2 from "../assets/lavori/tinte2.jpg";
import tinte3 from "../assets/lavori/tinte3.jpg";

export const phoneNumber = "+39 3937012272";
export const mail = "maringlen.vocaj@gmail.com";

export const soffittiArray = [soffitti1, soffitti2, soffitti3];
export const cartongessoArray = [cartongesso1, cartongesso2, cartongesso3];
export const tinteArray = [tinte1, tinte2, tinte3];
