import { styled } from "../styles/stitches.config";
import { useContext, useRef, VFC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { buttonVariants, toggleMenuAnimation } from "../styles/animations";
import { StyledButton } from "../styles/styledComponents";
import { ButtonContext } from "../context/ButtonContext";
import { motion } from "framer-motion";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { vGutter } from "../styles/params";

const Container = styled(motion.div, {
  boxShadow: `rgba(0, 0, 0, 0.5) 0px 2px 15px`,
  display: "grid",
  gridTemplateColumns: "1fr",
  gridColumn: "1/13",
  position: "fixed",
  bottom: 0,
  left: 0,
  zIndex: 1,
  width: "100vw",
  variants: {
    device: {
      large: {
        width: "50%",
        bottom: "50%",
        left: "50",
        transform: "translate(50%,50%)",
        borderRadius: `calc(${vGutter} / 2)`,
      },
    },
  },
});

export const Navbar: VFC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { buttons, handleOnClick } = useContext(ButtonContext);
  const ref = useRef(null);
  useOnClickOutside(
    ref,
    () => buttons.toggleMenu && handleOnClick("toggleMenu")
  );

  return (
    <Container
      device={{ "@bp3": "large" }}
      ref={ref}
      initial={false}
      {...toggleMenuAnimation}
      animate={buttons.toggleMenu ? "showMenu" : "notShow"}
    >
      <StyledButton
        onClick={() => {
          handleOnClick("toggleMenu");
          navigate("/");
        }}
        {...buttonVariants}
        animate={location.pathname === "/" ? "clicked" : "notClicked"}
      >
        Chi sono
      </StyledButton>
      <StyledButton
        onClick={() => {
          handleOnClick("toggleMenu");
          navigate("/contatti");
        }}
        {...buttonVariants}
        animate={location.pathname === "/contatti" ? "clicked" : "notClicked"}
      >
        Contattami
      </StyledButton>
      <StyledButton
        onClick={() => {
          handleOnClick("toggleMenu");
          navigate("/lavori");
        }}
        {...buttonVariants}
        animate={location.pathname === "/lavori" ? "clicked" : "notClicked"}
      >
        I miei lavori
      </StyledButton>
    </Container>
  );
};
