import { Variants, MotionProps } from "framer-motion";
import { colors } from "./params";

export const buttonVariants: { variants: Variants } & MotionProps = {
  variants: {
    notClicked: {
      scale: 1,
    },
    clicked: {
      backgroundColor: colors.yellow.base,
      color: colors.black.text,
    },
    secondaryNotClicked: {
      scale: 1,
      backgroundColor: colors.yellow.base,
      color: colors.black.text,
    },
  },
  whileHover: {
    backgroundColor: colors.yellow.base,
    color: colors.black.text,
    position: "relative",
    transition: { bounce: 1 },
  },
};

export const transitionIn: MotionProps = {
  initial: { x: "-100%" },
  animate: {
    x: 0,
    transition: {
      easings: "easeOut",
      duration: 0.3,
    },
  },
};

export const toggleMenuAnimation: { variants: Variants } = {
  variants: {
    showMenu: {
      height: "fit-content",
      transition: { ease: "circOut", duration: 0.2 },
    },
    notShow: {
      height: 0,
      overflow: "hidden",
      transition: { ease: "circOut", duration: 0.2 },
    },
  },
};
