import { createContext, ReactNode, useState } from "react";
import { match } from "ts-pattern";

interface ToggleButtons {
  toggleMenu: boolean;
}

type Actions = "toggleMenu";

type ButtonContextType = {
  buttons: ToggleButtons;
  handleOnClick: (button: Actions) => void;
};

export const ButtonContext = createContext<ButtonContextType>({
  buttons: { toggleMenu: false },
  handleOnClick: () => undefined,
});

export const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [buttons, setButtons] = useState<ToggleButtons>({
    toggleMenu: false,
  });

  const handleOnClick = (button: Actions): void => {
    match(button)
      .with("toggleMenu", () =>
        setButtons((prev) => ({ ...prev, toggleMenu: !prev.toggleMenu }))
      )
      .exhaustive();
  };

  return (
    <ButtonContext.Provider value={{ buttons, handleOnClick }}>
      {children}
    </ButtonContext.Provider>
  );
};
