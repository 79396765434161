import { styled } from "./stitches.config";
import { motion } from "framer-motion";
import { colors, hGutter, vGutter } from "./params";

export const StyledButton = styled(motion.button, {
  padding: `${vGutter} ${hGutter}`,
  boxShadow: `rgba(0, 0, 0, 0.5) 0px 2px 15px`,
  outline: "none",
  border: "1px solid transparent",
  cursor: "pointer",
  fontSize: "1.4rem",
  backgroundColor: colors.blue.light,
  textTransform: "uppercase",
  color: colors.gray.light,
  variants: {
    styling: {
      roundedCorner: { 
        borderRadius: `calc(${vGutter} / 2)` },
    },
  },
});
