import { styled } from "../styles/stitches.config";
import { VFC } from "react";
import { colors, defaultStyles, vGutter } from "../styles/params";
import Hi from "../assets/mario.webp";
import { motion } from "framer-motion";
import { buttonVariants, transitionIn } from "../styles/animations";
import { Memoji } from "../components/Memoji";
import { StyledButton } from "../styles/styledComponents";
import { useNavigate } from "react-router-dom";

const Section = styled(motion.section, defaultStyles, {
  display: "flex",
  flexDirection: "column",
  backgroundColor: colors.blue.base,
  lineHeight: "2rem",
  p: {
    textAlign: "left",
    marginTop: vGutter,
    marginBottom: vGutter,
  },
  large: {
    gridColumn: "3/11",
    margin: `calc(${vGutter} * 2) 0`,
    padding: `${vGutter} calc(${vGutter} * 2)`,
  },
});

const ButtonContainer = styled("div", {
  variants: {
    device: {
      small: {
        display: "flex",
        flexDirection: "column",
        button: {
          marginBottom: vGutter,
        },
      },
      large: {
        maxWidth: "100%",
        flexDirection: "row",
        columnGap: vGutter,
        button: { width: "100%" },
      },
    },
  },
});

export const AboutUs: VFC = () => {
  const navigate = useNavigate();

  return (
    <Section device={{ "@bp3": "large" }} {...transitionIn}>
      <Memoji img={Hi} />
      <h1>Ciao sono Mario</h1>
      <h2>il titolare di Edil Decorgesso</h2>
      <p>
        La mia ditta si occupa di lavori di tinteggiatura e piccola edilizia in
        tutta la provincia di Cuneo. Lavoro in questo settore da 14 anni e mi
        sono specializzato in pittura di interni ed esterni, con particolare
        riguardo a finiture decorative di ogni tipo (terre fiorentine,
        verniciatura a spruzzo, …) su pareti, legno e ferro. Grazie agli anni di
        esperienza sul campo e al percorso continuo di aggiornamento
        professionale, io e i miei fidati collaboratori ci siamo specializzati
        anche in lavori di piccola edilizia quali stuccatura, rasatura,
        isolamento cappotto e trattamenti antimuffa, controsoffittature e
        contropareti in cartongesso. Se hai bisogno di una mano, chiama Mario!
        Vi offriamo gratuitamente il nostro servizio di sopralluogo e
        preventivo, che sarà rapido e puntuale.
      </p>
      <ButtonContainer device={{ "@initial": "small", "@bp3": "large" }}>
        <StyledButton
          {...buttonVariants}
          styling={"roundedCorner"}
          onClick={() => navigate("/contatti")}
        >
          Contattami
        </StyledButton>
        <StyledButton
          {...buttonVariants}
          styling={"roundedCorner"}
          onClick={() => navigate("/lavori")}
        >
          I miei lavori
        </StyledButton>
      </ButtonContainer>
    </Section>
  );
};
